/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.chain_select{
  width:250px;
  height:40px;
  border:1px solid #DDD;
  display: flex;
  .value{
    flex:1;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    color: #333333;
    text-indent: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .select_bt{
    width:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEE;
    &::after{
      content:"";
      border-top:8px solid #999;
      border-left:8px solid transparent;
      border-right:8px solid transparent;
    }
  }
}
.options{
  min-width:252px;
  padding:2px 0;
  .option{
    padding:0 31px;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    line-height: 60px;
    color: #333333;
    border-bottom: 1px solid #DDD;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:last-child{
      border-bottom: 0;
    }
    &.selected{
      background-color: #d7e6fe;
      color: #498ffc;
    }
    &:hover{
      background-color: #eaf2ff;
      color: #5f9cfc;
    }
  }
}
