/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

 .chart{
  width:474px;
  height:302px;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
}
.chart_title{
  margin:20px 20px 0 20px;
  font-size: 16px;
  color: #323232;
}
.chart_time{
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.chart_c{
  margin:10px 15px 0 20px;
}
