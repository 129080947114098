/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

// .block_c{
//   width:1262px;
//   margin:32px auto 0;
// }
.block_more{
  font-weight: 400;
  font-size: 16px;
  color: #323232;
  img{
    vertical-align: middle;
    margin-bottom: 2px;
  }
}
.block_steps{
  margin:0 auto;
  width:1300px;
  height:136px;
  overflow: hidden;
  position: relative;
}
.block_steps_list{
  position: absolute;
  top:12px;
  left:0;
  display: flex;
  flex-wrap: nowrap;
}
.block_step_i{
  float: left;
  width:304px;
  height:124px;
  margin-right: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  background-image: linear-gradient(#b7defd, #f5f8fc ,white);
  position: relative;
  bottom:0px;
  transition: all  0.5s;
  &:hover{
    bottom:10px;
    text-decoration: none;
    box-shadow: 0px 4px 8px #999;
  }
}
.block_line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin:10px 20px;
}
.block_title{
  font-size: 22px;
  color: #323232;
  font-weight: 500;
}
.block_time{
  font-size: 14px;
  color: #758096;
  font-weight: 300;
}
.block_subtitle{
  font-size: 14px;
  color: #323232;
  white-space: nowrap;
}
.block_path{
  font-size: 14px;
  color: #323232;
  margin-left:20px;
}
