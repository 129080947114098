/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.table{
  margin-top:24px;
  thead{
    background-color: #E2EFFF;
    tr{
      background:transparent;
      height: 40px;
      th{
        padding: 4px 16px;
        &>div{
          font-size: 14px;
        }
      }
    }
  }
  tbody{
    tr{
      height: 40px;
      td{
        padding: 4px 16px;
        &>div{
          font-size: 14px;
        }
      }
    }
  }
  :global(.tea-btn){
    font-size: 14px;
    margin-left:10px;
  }
  :global(.tea-table)+:global(.tea-pagination){
    padding-top:20px;
  }
  :global(.tea-pagination) :global(.tea-pagination__text),:global(.tea-dropdown__value){
    font-size: 14px;
  }
}
.line{
  display: inline-block;
  width:1px;
  height: 13px;
  margin: 0 12px;
  background-color: #DDD;
}
.setting_c{
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    cursor: pointer;
  }
}

.searchform{
  display: inline-block;
  vertical-align: top;
  :global(.tea-form__label) label{
    font-size:14px;
    line-height: 24px;
  }
  >:global(.tea-form__item) :global(.tea-form__controls){
    padding-bottom: 0;
  }
  :global(.tea-input), :global(.tea-textarea), :global(.tea-textarea-group), :global(.tea-dropdown__header){
    height:40px;
    >:global(.tea-icon){
      top:12px;
    }
  }
  :global(.tea-dropdown-btn) :global(.tea-dropdown__value){
    line-height: 38px;
  }
  :global(.tea-datepicker__input):after,
  :global(.tea-datetimepicker__input):after{
    top:12px;
  }
  :global(.tea-form__item):last-child{
    margin-right: 0;
    :global(.tea-form__controls){
      padding-bottom: 0;
    }
  }
  :global(.tea-btn){
    font-size: 14px;
    font-weight: 400;
    height:40px;
    line-height: 38px;
    padding:0 30px;
  }
}
.value_td,.value_td_pre{
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 20px 20px 0;
  margin-right:-16px;
}
.value_td_pre{
  white-space: pre-wrap;
}
