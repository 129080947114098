/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.detail{
  :global(.tea-form-title){
    font-size: 16px;
    color: #333333;
    margin-top:36px;
  }
  :global(.tea-form){
    margin-top:15px;
    :global(.tea-form__label){
      label,legend{
        font-size:14px;
        color: #333333;
      }
    }
    :global(.tea-form__text){
      font-size: 14px;
      color: #333333;
    }
  }
  .pre{
    white-space: pre-wrap;
    border:1px solid #ddd;
    padding: 16px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  }
}
