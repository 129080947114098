/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
 
 .navs{
  margin-top: 36px;
  display: flex;
  border-bottom: 1px solid #eee;
  .nav{
    font-size: 16px;
    line-height: 30px;
    border-bottom: 3px solid white;
    margin-right:40px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    &.act{
      border-bottom: 3px solid #006eff;
      font-weight: bold;
    }
  }
}
.subtitle{
  font-size:14px;
  font-weight: 700;
  margin:20px 0 16px;
}
.text{
  font-size:14px;
  background-color: #e8e8e8;
  border:1px solid #ddd;
  max-height: 400px;
  padding: 20px;
}
.empty_word{
  margin-top:-60px;
  font-size:14px;
  color:#999;
  text-align: center;
}
.detail{
  width:100%;
  :global(.tea-form__item){
    width:50%;
    margin-right: 0;
  }
}