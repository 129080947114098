/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.nft_img{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-width: 100%;
    max-height: 100%;
  }
}
.nft_img_auto{
  width: 100%;
  height: 100%;
  background: url(/assets/svgs/CMDFA.svg) center no-repeat;
}
